export default [
  {
    title: 'Accounting',
    icon: 'GridIcon',
    children: [
      {
        title: 'Student',
        route: null,
        children: [
          {
            route: 'payment-collection',
            title: 'Payment Collections',
            action: 'payment-collection',
            resource: 'payment-collection',
          },
          {
            route: 'student-concessions',
            title: 'Student Concessions',
            action: 'student-concessions',
            resource: 'student-concessions',
          },
        ],
      },
      {
        title: 'Employee',
        route: null,
        children: [
          {
            route: 'salary-disbursements',
            title: 'Salary Disbursements',
          },
        ],
      },
      {
        route: 'vouchers',
        title: 'Vouchers',
        action: 'getVouchers',
        resource: 'vouchers',
      },
      {
        title: 'Fintech',
        route: null,
        children: [
          {
            title: 'Payment Service',
            route: 'fintech-service',
            action: 'fintech-service',
            resource: 'fintech-service',
          },
          {
            title: 'Fintech',
            route: 'fintech',
            action: 'fintech-menu',
            resource: 'fintech-menu',
          },
          {
            title: 'Fintech Service Provider',
            route: 'fintech-service-provider',
            action: 'fintech-service-provider',
            resource: 'fintech-service-provider',
          },
          {
            title: 'Merchant Settlement Account',
            route: 'merchant-settlement-account',
            action: 'merchant-settlement-account',
            resource: 'merchant-settlement-account',
          },
          {
            title: 'Fintech Institute Service',
            route: 'fintech-institute-service',
            action: 'fintech-institute-service',
            resource: 'fintech-institute-service',
          },
          {
            title: 'Fintech Merchant',
            route: 'fintech-merchant',
            action: 'fintech-merchant',
            resource: 'fintech-merchant',
          },
          {
            title: 'Institute Campus Merchant Services',
            route: 'institute-campus-merchant-service',
            action: 'ins-fintech',
            resource: 'ins-fintech',
          },
        ],
        action: 'fintech',
        resource: 'fintech',
      },
      {
        title: 'Configurations',
        icon: 'SettingsIcon',
        route: null,
        children: [
          {
            route: 'coa',
            title: 'Chart Of Accounts',
            // action: 'coa',
            // resource: 'coa',
            action: 'coa',
            resource: 'coa',
          },
          {
            route: 'coa-config',
            title: 'COA Config',
            action: 'coa-config',
            resource: 'coa-config',
          },
          {
            route: 'coa-mapping',
            title: 'COA Mapping',
            action: 'coa-mapping',
            resource: 'coa-mapping',
          },
          {
            route: 'std-fee-config',
            title: 'Student Fee Config',
            action: 'std-fee-config',
            resource: 'std-fee-config',
          },
          {
            route: 'payment-cycle-config',
            title: 'Payment Cycle Config',
            action: 'payment-cycle-config',
            resource: 'payment-cycle-config',
          },
          {
            route: 'advance-override',
            title: 'Advance Overrides',
            action: 'advance-override',
            resource: 'advance-override',
          },
          {
            route: 'invoice-print-settings',
            title: 'Invoice Print Setting',
            action: 'invoice-print-settings',
            resource: 'invoice-print-settings',
          },
          {
            route: 'vat-config',
            title: 'Vat Config',
          },
          {
            route: 'sms-notification',
            title: 'Sms Notification',
            action: 'sms-notification',
            resource: 'sms-notification',
          },
          {
            route: 'config-fine-management',
            title: 'Fine Management',
          },
          {
            route: 'franchise-revenue',
            title: 'Franchise Revenue',
          },
        ],
      },
      {
        title: 'Reports',
        icon: 'BarChartIcon',
        route: null,
        children: [
          {
            route: 'group-invoice',
            title: 'Group Invoice',
            action: 'group-invoice',
            resource: 'group-invoice',
          },
          {
            route: 'paid-report',
            title: 'Paid Report',
            action: 'paid-report',
            resource: 'paid-report',
          },
          {
            route: 'due-report',
            title: 'Due Report',
            action: 'due-report',
            resource: 'due-report',
          },
          {
            route: 'student-ledger',
            title: 'Student Ledger',
            action: 'student-ledger',
            resource: 'student-ledger',
          },
          {
            route: 'head-wise-ledger',
            title: 'Head Wise Ledger',
            action: 'head-wise-ledger',
            resource: 'head-wise-ledger',
          },
          {
            route: 'head-wise-summaries',
            title: 'Head Wise Summaries',
            action: 'head-wise-summaries',
            resource: 'head-wise-summaries',
          },
        ],
      },
    ],
    action: 'accounting',
    resource: 'accounting',
  },
]
