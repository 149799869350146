/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import LocalStorageService from '@/@service/utils/LocalStorage.service'
import dashboard from './dashboard'
// import migrations from './migrations'
import accounting from './accounting'
import resourceSharing from './resource-sharing'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'

// let token = LocalStorageService.get('accessToken')
// const debounce = (func, wait) => {
//   let timeout

//   return (...args) => {
//     if (timeout) clearTimeout(timeout)

//     timeout = setTimeout(() => func(...args), wait)
//   }
// }

// debounce(() => {
//   token = LocalStorageService.get('accessToken')
//   console.log(token)
// }, 500)

// Array of sections
export default [
  {
    title: 'Old Dashboard',
    icon: 'ExternalLinkIcon',
    route: 'old-dashboard',
    // href: `${process.env.VUE_APP_CORE_BASE_URL}login/fems_admin_reset_token/?t=${token}` || `http://core.ois.edu.bd/login/fems_admin_refresh_token/?t=${LocalStorageService.get('accessToken')}`,
    action: 'dashboard',
    resource: 'dashboard',
  },
  ...accounting,
  // ...migrations,
  ...resourceSharing,
  ...dashboard,

  // ...appsAndPages,
  // ...uiElements, ...formAndTable, ...chartsAndMaps,
  // ...others,
  //  ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others
  // {
  //   title: 'Demo Invoice',
  //   icon: 'FileTextIcon',
  //   route: 'student-invoice',
  // },
]
