export default [
  {
    route: 'audits',
    title: 'Audits',
    slug: 'audits',
    icon: 'GridIcon',

    // title: 'Accounting',
    // icon: 'GridIcon',
    // children: [
    //   {
    //     route: 'coa',
    //     title: 'Chart Of Accounts',
    //     // slug: 'chart-of-accounts',
    //   },
    //   {
    //     route: 'vouchers',
    //     title: 'Vouchers',
    //     // slug: 'chart-of-accounts',
    //   },
    // {
    //   title: 'eCommerce',
    //   route: 'dashboard-ecommerce',
    // },
    // {
    //   title: 'Analytics',
    //   route: 'dashboard-analytics',
    // },
    // ],
  },
  {
    route: 'oisacl-roles',
    title: 'Oisacl Roles',
    slug: 'oisacl-roles',
    icon: 'UserIcon',
    action: 'oisacl-roles',
    resource: 'oisacl-roles',
  },
  // {
  //   title: 'Resource Sharing',
  //   icon: 'GridIcon',
  //   children: [
  //     {
  //       route: 'config-resource-share-add-category',
  //       title: 'Category Names',
  //       // slug: 'config-resource-share-add-category',
  //     },
  //     {
  //       route: 'config-resource-share-permission-settings',
  //       title: 'Permission Settings',
  //       // slug: 'chart-of-accounts',
  //     },
  //     {
  //       route: 'config-resource-share-file-upload',
  //       // title: 'File Uploads',
  //       // slug: 'chart-of-accounts',
  //     },
  //   ],
  // },
]
