<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.first_name +" "+ userData.last_name }}
        </p>
        <!--        <span class="user-status">{{ userData.role }}</span>-->
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.first_name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'profile-password-change'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="LockIcon"
        class="mr-50"
      />
      <span>Change Passwrod</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
  // BDropdownDivider,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
// import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
// import AuthService from '@/@service/api/Auth.service'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
      avatarText,
    }
  },
  methods: {
    logout() {
      // AuthService.logOut().then((res => {
      //   console.log(res)
      //   this.onLogout()
      // }), error => {
      //   console.log(error)
      //   this.onLogout()
      // })
      this.onLogout()
    },
    onLogout() {
      // Reset ability
      this.$ability.update(initialAbility)
      LocalStorageService.clear()
      if (process.env.VUE_APP_ENV_NAME !== 'local') {
        window.location.href = `${process.env.VUE_APP_CORE_BASE_URL}login/logout_alt`
      }

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
<style lang="scss">

ul.dropdown-menu.dropdown-menu-right.show {
    width: 14rem!important;
}
</style>
