export default [
  {
    title: 'Resource Sharing',
    icon: 'GridIcon',
    children: [
      {
        route: 'config-resource-share-document-type',
        title: 'Document Type',
        action: 'customResource',
        resource: 'customResource',
      },
      {
        route: 'config-resource-share-permission-settings',
        title: 'Permission Settings',
        action: 'customResource',
        resource: 'customResource',
      },
      {
        route: 'config-resource-share-document-upload',
        title: 'Document Uploads',
        action: 'resourceSharing',
        resource: 'resourceSharing',
      },
      {
        title: 'Report',
        route: null,
        children: [
          {
            title: 'Document List',
            route: 'report-resource-share-document-list',
            action: 'customResource',
            resource: 'customResource',
          },
        ],
        action: 'customResource',
        resource: 'customResource',
      },
    ],
    action: 'resourceSharing',
    resource: 'resourceSharing',
  },
]
